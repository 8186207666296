import { default as accept_45invitemJTiBLtiZ3Meta } from "/vercel/path0/pages/accept-invite.vue?macro=true";
import { default as _91game_93esERwCDxvjMeta } from "/vercel/path0/pages/classes/[week]/[game].vue?macro=true";
import { default as indexrzYly5uEsVMeta } from "/vercel/path0/pages/classes/[week]/index.vue?macro=true";
import { default as index0GHCrg5TyqMeta } from "/vercel/path0/pages/classes/index.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as create_45profilezKv3oDT3JWMeta } from "/vercel/path0/pages/create-profile.vue?macro=true";
import { default as indexqA3BiC6hTPMeta } from "/vercel/path0/pages/dashboard/admin/index.vue?macro=true";
import { default as inviteEfE3lcpogiMeta } from "/vercel/path0/pages/dashboard/admin/invite.vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as indexzQf9QKp7OdMeta } from "/vercel/path0/pages/dashboard/student/index.vue?macro=true";
import { default as insightsCEYBO2TgiUMeta } from "/vercel/path0/pages/dashboard/student/insights.vue?macro=true";
import { default as system_45adminmUiwPL85cWMeta } from "/vercel/path0/pages/dashboard/system-admin.vue?macro=true";
import { default as index4iPsYaFJ5tMeta } from "/vercel/path0/pages/dashboard/teacher/index.vue?macro=true";
import { default as inviteRFoe8hDh85Meta } from "/vercel/path0/pages/dashboard/teacher/invite.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as loginOTPgtz5slhqAZMeta } from "/vercel/path0/pages/loginOTP.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
export default [
  {
    name: accept_45invitemJTiBLtiZ3Meta?.name ?? "accept-invite",
    path: accept_45invitemJTiBLtiZ3Meta?.path ?? "/accept-invite",
    meta: accept_45invitemJTiBLtiZ3Meta || {},
    alias: accept_45invitemJTiBLtiZ3Meta?.alias || [],
    redirect: accept_45invitemJTiBLtiZ3Meta?.redirect,
    component: () => import("/vercel/path0/pages/accept-invite.vue").then(m => m.default || m)
  },
  {
    name: _91game_93esERwCDxvjMeta?.name ?? "classes-week-game",
    path: _91game_93esERwCDxvjMeta?.path ?? "/classes/:week()/:game()",
    meta: _91game_93esERwCDxvjMeta || {},
    alias: _91game_93esERwCDxvjMeta?.alias || [],
    redirect: _91game_93esERwCDxvjMeta?.redirect,
    component: () => import("/vercel/path0/pages/classes/[week]/[game].vue").then(m => m.default || m)
  },
  {
    name: indexrzYly5uEsVMeta?.name ?? "classes-week",
    path: indexrzYly5uEsVMeta?.path ?? "/classes/:week()",
    meta: indexrzYly5uEsVMeta || {},
    alias: indexrzYly5uEsVMeta?.alias || [],
    redirect: indexrzYly5uEsVMeta?.redirect,
    component: () => import("/vercel/path0/pages/classes/[week]/index.vue").then(m => m.default || m)
  },
  {
    name: index0GHCrg5TyqMeta?.name ?? "classes",
    path: index0GHCrg5TyqMeta?.path ?? "/classes",
    meta: index0GHCrg5TyqMeta || {},
    alias: index0GHCrg5TyqMeta?.alias || [],
    redirect: index0GHCrg5TyqMeta?.redirect,
    component: () => import("/vercel/path0/pages/classes/index.vue").then(m => m.default || m)
  },
  {
    name: confirm2uAIxw9fKZMeta?.name ?? "confirm",
    path: confirm2uAIxw9fKZMeta?.path ?? "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    alias: confirm2uAIxw9fKZMeta?.alias || [],
    redirect: confirm2uAIxw9fKZMeta?.redirect,
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: create_45profilezKv3oDT3JWMeta?.name ?? "create-profile",
    path: create_45profilezKv3oDT3JWMeta?.path ?? "/create-profile",
    meta: create_45profilezKv3oDT3JWMeta || {},
    alias: create_45profilezKv3oDT3JWMeta?.alias || [],
    redirect: create_45profilezKv3oDT3JWMeta?.redirect,
    component: () => import("/vercel/path0/pages/create-profile.vue").then(m => m.default || m)
  },
  {
    name: indexqA3BiC6hTPMeta?.name ?? "dashboard-admin",
    path: indexqA3BiC6hTPMeta?.path ?? "/dashboard/admin",
    meta: indexqA3BiC6hTPMeta || {},
    alias: indexqA3BiC6hTPMeta?.alias || [],
    redirect: indexqA3BiC6hTPMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/index.vue").then(m => m.default || m)
  },
  {
    name: inviteEfE3lcpogiMeta?.name ?? "dashboard-admin-invite",
    path: inviteEfE3lcpogiMeta?.path ?? "/dashboard/admin/invite",
    meta: inviteEfE3lcpogiMeta || {},
    alias: inviteEfE3lcpogiMeta?.alias || [],
    redirect: inviteEfE3lcpogiMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/admin/invite.vue").then(m => m.default || m)
  },
  {
    name: indexRIIpWdUktDMeta?.name ?? "dashboard",
    path: indexRIIpWdUktDMeta?.path ?? "/dashboard",
    meta: indexRIIpWdUktDMeta || {},
    alias: indexRIIpWdUktDMeta?.alias || [],
    redirect: indexRIIpWdUktDMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexzQf9QKp7OdMeta?.name ?? "dashboard-student",
    path: indexzQf9QKp7OdMeta?.path ?? "/dashboard/student",
    meta: indexzQf9QKp7OdMeta || {},
    alias: indexzQf9QKp7OdMeta?.alias || [],
    redirect: indexzQf9QKp7OdMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/student/index.vue").then(m => m.default || m)
  },
  {
    name: insightsCEYBO2TgiUMeta?.name ?? "dashboard-student-insights",
    path: insightsCEYBO2TgiUMeta?.path ?? "/dashboard/student/insights",
    meta: insightsCEYBO2TgiUMeta || {},
    alias: insightsCEYBO2TgiUMeta?.alias || [],
    redirect: insightsCEYBO2TgiUMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/student/insights.vue").then(m => m.default || m)
  },
  {
    name: system_45adminmUiwPL85cWMeta?.name ?? "dashboard-system-admin",
    path: system_45adminmUiwPL85cWMeta?.path ?? "/dashboard/system-admin",
    meta: system_45adminmUiwPL85cWMeta || {},
    alias: system_45adminmUiwPL85cWMeta?.alias || [],
    redirect: system_45adminmUiwPL85cWMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/system-admin.vue").then(m => m.default || m)
  },
  {
    name: index4iPsYaFJ5tMeta?.name ?? "dashboard-teacher",
    path: index4iPsYaFJ5tMeta?.path ?? "/dashboard/teacher",
    meta: index4iPsYaFJ5tMeta || {},
    alias: index4iPsYaFJ5tMeta?.alias || [],
    redirect: index4iPsYaFJ5tMeta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/teacher/index.vue").then(m => m.default || m)
  },
  {
    name: inviteRFoe8hDh85Meta?.name ?? "dashboard-teacher-invite",
    path: inviteRFoe8hDh85Meta?.path ?? "/dashboard/teacher/invite",
    meta: inviteRFoe8hDh85Meta || {},
    alias: inviteRFoe8hDh85Meta?.alias || [],
    redirect: inviteRFoe8hDh85Meta?.redirect,
    component: () => import("/vercel/path0/pages/dashboard/teacher/invite.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginOTPgtz5slhqAZMeta?.name ?? "loginOTP",
    path: loginOTPgtz5slhqAZMeta?.path ?? "/loginOTP",
    meta: loginOTPgtz5slhqAZMeta || {},
    alias: loginOTPgtz5slhqAZMeta?.alias || [],
    redirect: loginOTPgtz5slhqAZMeta?.redirect,
    component: () => import("/vercel/path0/pages/loginOTP.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register",
    path: registerIikvtlfpbHMeta?.path ?? "/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  }
]