import revive_payload_client_dGeraiLcK7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@14.18.33_@unocss+reset@0.60.3_eslint@8.57.0__esw32jzgvg7vebuejea32u7u6u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CDdO6w1Cni from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@14.18.33_@unocss+reset@0.60.3_eslint@8.57.0__esw32jzgvg7vebuejea32u7u6u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_X9bC7IC2h2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@14.18.33_@unocss+reset@0.60.3_eslint@8.57.0__esw32jzgvg7vebuejea32u7u6u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ak556yCYcT from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.1_@vue+compiler-core@3.5.13_fuse.js@7.0.0_nuxt@3.1_xyzhzx3q3z2ijqlmwewm4fu5c4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import supabase_client_tGZgdY5B9K from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.2/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_XaDWMfabxF from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@14.18.33_@unocss+reset@0.60.3_eslint@8.57.0__esw32jzgvg7vebuejea32u7u6u/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_TZbsBbxelp from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@14.18.33_@unocss+reset@0.60.3_eslint@8.57.0__esw32jzgvg7vebuejea32u7u6u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A8CQOEUAxs from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.4.5_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_eymR15OHCn from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@14.18.33_@unocss+reset@0.60.3_eslint@8.57.0__esw32jzgvg7vebuejea32u7u6u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_5gWAGgSEhY from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.3.1_@unhead+shared@1.9.10_@vue+c_szbtr7n4npnbkqja5trahwfd7q/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_naWAifXVM0 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.3.1_@unhead+shared@1.9.10_@vue+c_szbtr7n4npnbkqja5trahwfd7q/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_nB5l5csEaE from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.3.1_@vue+compiler-core@3.5.13_nuxt@3.11.2_po_emr2eewjvxia367bmuhz2kox6e/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_4xlSAzszPX from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.3.1_@vue+compiler-core@3.5.13_nuxt@3.11.2_po_emr2eewjvxia367bmuhz2kox6e/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_Te8lfITPyP from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_gNW07499b5 from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.4.11_pinia@2.1.7/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import preview_client_ooG3jeyevT from "/vercel/path0/node_modules/.pnpm/@nuxthq+studio@2.2.1/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import slideovers_ucrElFxEqP from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_typescript@5.4.5_vite@5.2.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_faHwocVYql from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_typescript@5.4.5_vite@5.2.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_EQWkWxWwKz from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_typescript@5.4.5_vite@5.2.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_gMuYIofanj from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_vite@5.2.11_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_he0U0CYwZq from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_CfmajFsNca from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@14.18.33_@unocss+reset@0.60.3_eslint@8.57.0__esw32jzgvg7vebuejea32u7u6u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import chartjs_1wC9lAM0Tp from "/vercel/path0/plugins/chartjs.ts";
import error_handler_VFH3VvK7yG from "/vercel/path0/plugins/error-handler.js";
import hasUser_EU8seoLkzI from "/vercel/path0/plugins/hasUser.ts";
import hotjar_client_HJG8no80GI from "/vercel/path0/plugins/hotjar.client.ts";
export default [
  revive_payload_client_dGeraiLcK7,
  unhead_CDdO6w1Cni,
  router_X9bC7IC2h2,
  _0_siteConfig_ak556yCYcT,
  supabase_client_tGZgdY5B9K,
  payload_client_XaDWMfabxF,
  check_outdated_build_client_TZbsBbxelp,
  plugin_vue3_A8CQOEUAxs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_eymR15OHCn,
  titles_5gWAGgSEhY,
  defaults_naWAifXVM0,
  siteConfig_nB5l5csEaE,
  inferSeoMetaPlugin_4xlSAzszPX,
  plugin_Te8lfITPyP,
  plugin_gNW07499b5,
  preview_client_ooG3jeyevT,
  slideovers_ucrElFxEqP,
  modals_faHwocVYql,
  colors_EQWkWxWwKz,
  plugin_gMuYIofanj,
  plugin_client_he0U0CYwZq,
  chunk_reload_client_CfmajFsNca,
  chartjs_1wC9lAM0Tp,
  error_handler_VFH3VvK7yG,
  hasUser_EU8seoLkzI,
  hotjar_client_HJG8no80GI
]